.application-container {
  overflow-x: hidden;
  max-width: 100vw; 
  padding: 0; 
}

.application-head, .application-forme {
  padding: 30px;
} 

.application-image-col {
  position: relative;
}

.application-image-bg {
  position: relative;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 100%), url('../images/application.png');
  background-size: cover;
  background-position: center;
  height: 100%;
}

.application-head {
  font-family: 'Albert Sans', sans-serif;
  font-size: 36px;
  font-weight: 600;
  line-height: 54px;
}

.application-subhead {
  font-family: 'Albert Sans', sans-serif;
  font-size: 14px;
  padding-left: 30px;
  margin-top: -4%;
}

.application-button {
  width: 100%;
  background-color: #22CC9D;
  border-radius: 6px;
  border: none;
  font-family: 'Albert Sans', sans-serif;
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  text-align: center;
  color: white;
  padding: 10px;
}

.application-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0;
}

.application-overlay-content {
  position: absolute;
  bottom: 0px;
  left: 0;
  right: 0;
  text-align: center;
  padding: 10px;
}

.application-image-description {
  font-family: 'Albert Sans', sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #FFFFFF;
  width: 100%;
  margin-top: 4%;
  padding-left: 5px;
  text-align: left;
}

.custom-file-upload {
  display: flex;
  align-items: center;
  position: relative;
  background-color: #f8f9fa;
  padding: 10px;
  border: 1px solid #ced4da;
  border-radius: 5px;
  cursor: pointer;
}

.custom-file-placeholder {
  flex-grow: 1;
  color: #6c757d;
}

.upload-icon {
  color: #007bff;
  margin-left: auto;
}

.application-file-input {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  cursor: pointer;
}

.application-social-icons {
  margin-top: 25px;
  text-align: left;
}

.application-social-icon {
  margin: 0 10px;
  width: 25.84px;
  height: 26px;
  color: white;
}

.application-input-with-icon {
  position: relative;
}

.application-input-icon {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #6c757d;
}

.application-icon-input {
  padding-left: 45px; 
  text-indent: 20px;  
  border: 1px solid #BDBDBD !important;
  border-radius: 6px !important;
  padding: 15px !important;
  font-size: 16px !important;
  font-family: 'Albert Sans', sans-serif;
  font-weight: 600 !important;
  line-height: 24px !important;
  letter-spacing: -0.03em;
  text-align: left;
}
.overlay2 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it's above the form */
}
.application-icon-input::placeholder {
  color: #828282 !important; 
  opacity: 1; 
}

/* Responsive Adjustments */
@media (max-width: 992px) {
  .application-head {
      font-size: 28px;
      line-height: 42px;
  }
  .application-subhead {
      font-size: 12px;
      padding-left: 20px;
  }
  .application-button {
      font-size: 18px;
      line-height: 28px;
      padding: 8px;
  }
  .application-image-description {
      font-size: 14px;
  }
}

@media (max-width: 768px) {
  .application-head {
      font-size: 24px;
      line-height: 36px;
  }
  .application-subhead {
      font-size: 14px;
      padding-left: 15px;
  }
  .application-button {
      font-size: 16px;
      line-height: 24px;
      padding: 6px;
  }
  .application-image-description {
      font-size: 12px;
  }
  .application-image-col {
display: none;  }
}

@media (max-width: 576px) {
  .application-head {
      font-size: 20px;
      line-height: 30px;
      padding: 10px;
  }
  .application-subhead {
      font-size: 15px;
      padding-left: 10px;
      margin-top: -2%;
  }
  .application-button {
      font-size: 14px;
      line-height: 20px;
      padding: 5px;
  }
  .application-image-description {
      font-size: 10px;
  }
  .application-forme{
    padding: 10px;
  }
  .application-overlay-content{
    padding: 10px;
  } 
  .application-image{
    display: none;
  }
}
