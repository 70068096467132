.testimonial-container {
    text-align: center;
    padding: 20px;
    background: linear-gradient(rgba(17, 102, 79, 0.6), rgba(17, 102, 79, 0.6)), url('../images/testimonialbg.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
  
  .testimonial-image {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensures the image fills the circle proportionally */
  }
.testimonial-head {
    font-family: 'Albert Sans', sans-serif;
    font-size: 28px;
    font-weight: 600;
    color: #22CC9D;
}

.testimonial-subhead {
    font-family: 'Albert Sans', sans-serif;
    font-size: 32px;
    font-weight: 600;
    color: #FFFFFF;
}

.testimonial-cards {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 400px;
    margin-top: 7%;
}

.testimonial-card {
    width: 46%;
    height: 450px;
    border-radius: 12px;
    padding: 20px;
    background-color: #FFFFFF;
    color: #11664F;
    position: absolute;
    transition: transform 0.5s ease, opacity 0.5s ease;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
    opacity: 0.5;

}
.center-card.animate {
  animation: fadeInScale 0.8s ease forwards;
}

/* Left and right cards */
.left-card.animate {
  animation: slideLeftOut 0.8s ease forwards;
}

.right-card.animate {
  animation: slideRightOut 0.8s ease forwards;
}


.center-card {
    z-index: 3;
    transform: translateX(0) scale(1);
    opacity: 1;
    animation: slideToCenter 0.5s ease forwards;
    box-shadow: 0px 0px 120px 0px #000000A3;
    height: 450px; /* Full height for center card */
}

.left-card, .right-card {
    height: 400px; /* Reduced height for left and right cards */
    opacity: 0.6;
    z-index: 2;
    box-shadow: 24px 24px 80px 0px #00000070;
}

.left-card {
    transform: translateX(-63%) scale(0.85);
}

.right-card {
    transform: translateX(63%) scale(0.85);
}

.testimonial-name,
.testimonial-sub-name,
.testimonial-text {
    text-align: center;
}

.testimonial-name {
    font-family: 'Albert Sans', sans-serif;
    font-size: 19.6px;
    font-weight: 600;
    line-height: 32.4px;
    color: #000000;
    margin-top: 50px; /* Add margin to move it below the image */
}

.testimonial-sub-name {
    font-family: 'Albert Sans', sans-serif;
    font-size: 21.6px;
    font-weight: 600;
    line-height: 32.4px;
    color: #A3A3A3;
}

.testimonial-text {
    font-family: 'Barlow', sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    color: #000000;
    margin-top: 10px;
}

.testimonial-centertext {
    font-family: 'Barlow', sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    color: #000000;
}

.testimonial-centername {
    font-family: 'Albert Sans', sans-serif;
    font-size: 22px;
    font-weight: 600;
    line-height: 36px;
    color: #000000;
    margin-top: 50px;
}

.testimonial-centersub-name {
    font-family: 'Albert Sans', sans-serif;
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
    color: #A3A3A3;
}

.circle-image {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    overflow: hidden;
    position: absolute;
    top: -40px;
    left: 50%;
    transform: translateX(-50%);
    border: 4px solid #FFFFFF;
    background-color: #000000;
}

.circle-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.placeholder-text {
    color: white;
    font-weight: bold;
    font-size: 10px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  
/* Animation for sliding to the center */
@keyframes slideToCenter {
    0% {
      transform: translateX(50%) scale(0.85);
      opacity: 0.5;
    }
    100% {
      transform: translateX(0) scale(1);
      opacity: 1;
    }
}
@media (max-width: 768px) {
    .center-card {
      animation: none; /* Ensures no animation on smaller screens */
    }
  }

.testimonial-dots {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.dot {
    width: 12px;
    height: 12px;
    margin: 0 6px;
    background-color: #A3A3A3;
    border-radius: 50%;
    opacity: 0.6;
    cursor: pointer;
    transition: opacity 0.3s ease, transform 0.3s ease;
    margin-top: 4%;
}

.dot.active {
    opacity: 1;
    transform: scale(1.2);
    background-color: #22CC9D;
}







/* Adjustments for medium screens (up to 1200px) */
@media (max-width: 1200px) {
    .testimonial-head {
        font-size: 26px; /* Adjusted for better readability */
    }

    .testimonial-subhead {
        font-size: 30px; /* Larger subheading for medium screens */
    }

    .testimonial-cards {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        height: auto;
      }

    .center-card {
        height: 420px;
    }

    .testimonial-name,
    .testimonial-sub-name,
    .testimonial-centername,
    .testimonial-centersub-name {
        font-size: 20px; /* Increased size for better visibility */
    }

    .testimonial-text,
    .testimonial-centertext {
        font-size: 18px; /* Adjusted for clarity */
    }

    .circle-image {
        width: 80px; /* Slightly larger image for better visibility */
        height: 80px;
        top: -40px;
    }

    .dot {
        width: 12px;
        height: 12px; /* Slightly larger for better visibility */
    }
    .left-card, .right-card {
        display: none;
    }

}

/* Adjustments for smaller screens (up to 768px) */
@media (max-width: 768px) {
    .testimonial-card {
      width: 100%;
      position: static;
      margin: 12px 0;
      box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
      opacity: 1;
      transform: translateX(0) scale(1);
      transition: transform 0.5s ease-in-out;
    }
  
    .testimonial-cards {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      height: auto;
    }
  
    .left-card, .right-card {
      display: none;
    }
  
    .testimonial-dots {
      display: flex;
      justify-content: center;
      margin-top: 10px;
    }
    
    .dot {
      cursor: pointer;
      width: 10px;
      height: 10px;
      margin: 0 5px;
      background-color: #ccc;
      border-radius: 50%;
      transition: background-color 0.3s;
    }
  
    .dot.active {
      background-color: #333;
    }
  }
  

/* Adjustments for very small screens (up to 480px) */
@media (max-width: 480px) {
    .testimonial-head,
    .testimonial-subhead {
        font-size: 22px; /* Slightly larger text for very small screens */
    }

    .testimonial-card {
        height: auto;
        padding: 12px; /* Increase padding slightly for readability */
    }

    .testimonial-name,
    .testimonial-sub-name,
    .testimonial-centername,
    .testimonial-centersub-name {
        font-size: 16px; /* Larger text for better visibility */
    }

    .testimonial-text,
    .testimonial-centertext {
        font-size: 14px; /* Larger text for better readability */
    }

    .circle-image {
        width: 60px; /* Slightly larger image for very small screens */
        height: 60px;
        top: -30px;
    }

    .dot {
        width: 8px;
        height: 8px; /* Larger dots for better accessibility */
    }
}
