/* About.css */
.note-container {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end; /* Align content to the right */
    padding-right: 50px; /* Add some padding to the right side */
    color: white; /* White text for contrast */
    overflow: hidden;
  }
  .note{
    font-family: 'Albert Sans', sans-serif;
font-size: 20px;
font-weight: 600;
line-height: 30px;
letter-spacing: -0.03em;
text-align: left;
color: #A3A3A3;
  }
  
  .note-container::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url('../images/hrmnote.jpeg') center/cover no-repeat;
    filter: brightness(70%);
    z-index: -2;
  }
  
  .note-container::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, rgba(17, 102, 79, 0.4) 0%, #11664F 100%);
    z-index: -1;
  }
  
  .note-content {
    position: relative;
    text-align: left;
    max-width: 65%;
    padding: 20px;
    z-index: 1;
    border-radius: 10px;
  }
  .notebottom{
    font-family: 'Barlow', sans-serif;
font-size: 32px;
font-weight: 500;
line-height: 48px;
letter-spacing: -0.03em;
text-align: left;

  }
  /* Headline styling */
  .notehead {
    font-family: 'Albert Sans', sans-serif;
    font-size: 32px;
    font-weight: 600;
    line-height: 48px;
    letter-spacing: -0.03em;
    text-align: left;
  }
  
  /* Paragraph styling */
  .notepara {
    font-family: 'Barlow', sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 35px;
    letter-spacing: -0.03em;
    text-align: justify;
    margin-top: 3%;
  }
  
  /* Responsive adjustments */
  @media screen and (max-width: 1200px) {
    .note-container {
      padding-right: 30px;
    }
    .notebottom{
        font-size: 32px;
    }
    .note-content {
      max-width: 500px;
    }
  
    .notehead {
      font-size: 28px;
      line-height: 42px;
    }
  
    .notepara {
      font-size: 18px;
      line-height: 32px;
    }
    .note {
        font-size: 18px;
        line-height: 32px;
      }
  }
  
  @media screen and (max-width: 992px) {
    .note-container {
      justify-content: center; /* Center content for medium screens */
      padding-right: 20px;
    }
    .notebottom{
        font-size: 30px;
    }
    .note-content {
      max-width: 450px;
      padding: 20px;
    }
  
    .notehead {
      font-size: 26px;
      line-height: 40px;
    }
  
    .notepara {
      font-size: 16px;
      line-height: 30px;
    }
    .note {
        font-size: 16px;
        line-height: 30px;
      }
  }
  
  @media screen and (max-width: 768px) {
    .note-container {
      padding-right: 10px;
    }
    .notebottom{
        font-size: 28px;
    }
    .note-content {
      max-width: 400px;
      padding: 20px;
    }
  
    .notehead {
      font-size: 24px;
      line-height: 38px;
    }
  
    .notepara {
      font-size: 16px;
      line-height: 28px;
    }
    .note {
        font-size: 16px;
        line-height: 28px;
      }
  }
  
  @media screen and (max-width: 576px) {
    .note-container {
      padding: 0 10px; /* Reduce padding for small screens */
    }
    .notebottom{
        font-size: 26x;
    }
    .note-content {
      max-width: 100%;
      padding: 15px;
    }
  
    .notehead {
      font-size: 22px;
      line-height: 36px;
    }
  
    .notepara {
      font-size: 15px;
      line-height: 26px;
    }
    .note {
        font-size: 15px;
        line-height: 26px;
      }
  }
  
  @media screen and (max-width: 400px) {
    .note-container {
      justify-content: center;
    }
    .notebottom{
        font-size: 18px;
    }
    .note-content {
      padding: 13px;
    }
  
    .notehead {
      font-size: 20px;
      line-height: 34px;
    }
  
    .notepara {
      font-size: 14px;
      line-height: 24px;
    }
    .note {
        font-size: 14px;
        line-height: 24px;
      }
  }
  