
.whychoose-container {
    padding: 20px 20px !important; 
    max-width: 100% !important; 
    
      overflow-x: hidden;
    
}
/* Header animation */
@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.header-fade-in {
  animation: fadeInDown 1s ease-out forwards;
}

.whychoose-header {
    padding-left: 30px; 
    padding-right: 30px; 
    padding-top: 20px;
}
  .ourvision, .ourmission{
    font-family: 'Albert Sans', sans-serif;
font-size: 32px;
font-weight: 600;
line-height: 48px;
letter-spacing: -0.03em;
text-align: left;
color: #22CC9D;
padding: 20px;
  }
  .ourmissionpara, .ourvisionpara {
    font-family: 'Barlow', sans-serif;
font-size: 20px;
font-weight: 400;
line-height: 30px;
letter-spacing: -0.03em;
text-align: left;
color: #000000;
padding-left: 20px;
  }
  
  /* Slide-in animations */
@keyframes slideInLeft {
  from {
    opacity: 0;
    transform: translateX(-100px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideInRight {
  from {
    opacity: 0;
    transform: translateX(100px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

/* Apply initial hidden state */
.ourvision, .ourmission {
  opacity: 0;
  transition: opacity 0.8s ease-out;
  text-align: center;
}


/* Animation trigger classes */
.ourvision.animate-left {
  animation: slideInLeft 1s forwards;
}

.ourmission.animate-right {
  animation: slideInRight 1s forwards;
}

  .whychoose-title {
    font-family: 'Albert Sans', sans-serif;
    font-size: 28px;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: -0.03em;
    text-align: center;
    color: #5C5C5C;

  }
  
  .whychoose-subtitle {
    font-family: 'Albert Sans', sans-serif;
font-size: 32px;
font-weight: 600;
line-height: 48px;
letter-spacing: -0.03em;
text-align: center;
color: #22CC9D;
  }
  
  .whychoose-description {
    font-family: 'Barlow', sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 35px;
    letter-spacing: -0.03em;
    text-align: left;
    color: #000000;
  }
  
 /* Hover Animation for Card */
.whychoose-card {
  background-color: transparent;
  cursor: pointer;
  border: none !important;
  padding: 14px;
  border-radius: 8px;
  opacity: 0; /* Initially hidden */
  transform: translateY(50px); /* Position it slightly down for a slide-up effect */
  transition: transform 0.8s ease-in-out, opacity 0.8s ease-in-out;
}

.whychoose-card.fade-in {
  opacity: 1;
  transform: translateY(0); /* Slide up into place */
}



/* Fade-in Animation for the Icon */
@keyframes fadeIn {
  from {
      opacity: 0;
  }
  to {
      opacity: 1;
  }
}

  
  /* Card Title */
  .card-title {
    font-family: 'Albert Sans', sans-serif;
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
    letter-spacing: -0.03em;
    text-align: left;
    color: #000000CC;
    text-align: center;
    margin-top: 4%;
  }
  
  /* Icon Square Styling */
  
  
  .card-icon-img {
   font-size: 40px;
   
    
    
  }
  
  
  .card-text {
    font-family: 'Barlow', sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: -0.03em;
    text-align: center;
    color: rgba(0, 0, 0, 0.918);
  }
  
  @media (max-width: 768px) {
    .whychoose-container {
      padding: 20px !important; 
    }
  
    .whychoose-header {
      padding-left: 0px; 
      padding-top: 10px; 
    }
    .ourvision, .ourmission{
      font-size: 28px; 
      padding: 0px;
    }
    .ourmissionpara, .ourvisionpara{
      font-size: 16px; 
      padding: 0px;
    }
    .whychoose-title {
      font-size: 25px; 
      line-height: 34px; 
    }
    .whychoose-subtitle{
font-size: 28px;
    }
  .card-text{
font-size: 15px;
  }
  .card-title{
    font-size: 19px;
  } 
    .whychoose-description {
      font-size: 16px; 
      line-height: 24px; 
    }
  }
  