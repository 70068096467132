
  .landingheader {
    position: relative;
    height: 100vh;
    background: linear-gradient(270deg, rgb(0 0 0 / 8%) 0%, rgb(0 0 0 / 88%) 100%, rgb(0 0 0) 100%);

  }
  
  .background-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensures the video covers the entire area */
    z-index: -1; /* Ensures the video is behind the content */
  }
  
  .landingheader::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1; /* Gradient overlay on top of the video */
  }
  