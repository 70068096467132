.footer {
  padding: 40px 30px;
}

.footerhead {
  font-family: 'Albert Sans', sans-serif;
  font-size: 24px;
  font-weight: 600;
  line-height: 28.8px;
  text-align: left;
  color: #000000;
}

/* Responsive Footer Column */
.footer-column {
  padding: 10px;
}

/* Subheading */
.footersub {
  font-family: 'Albert Sans', sans-serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  text-align: left;
  color: #000000;
  width: 75%;
}

/* Footer Logo */
.footer-logo {
  width: 331px;
  height: 260px;
  top: 316px;
  left: 66px;
  gap: 0px;
  opacity: 1px;
  margin-left: -14px;
}

/* Social Icons */
.social-icons i {
  width: 40px;
  height: 40px;
  font-size: 24px;
  margin-right: 10px;
  color: #000000;
  cursor: pointer;
}

/* Footer Button */
.footer-button {
  background-color: #000000;
  color: white;
  max-width: 331px;
  width: 100%;
  height: 54px;
  padding: 7px 22px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: 'Albert Sans', sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

/* Links and Services */
.footer-links,
.footer-services {
  list-style: none;
  padding-left: 0;
}

.footer-links li,
.footer-services li {
  margin-bottom: 13px;
}

.footer-links a,
.footer-services li {
  font-family: 'Barlow', sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 34px;
  color: #000000;
  text-decoration: none;
  white-space: nowrap;
}

.footer-links a:hover,
.footer-services a:hover {
  text-decoration: none;

}


.footer-input-container {
  position: relative;
  max-width: 331px;
  width: 100%;
}

.footer-input {
  background-color: #000000;
  color: white;
  width: 100%;
  height: 54px;
  padding: 7px 22px;
  padding-right: 50px; /* Space for icon */
  border-radius: 4px;
  border: none;
  font-family: 'Albert Sans', sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  box-sizing: border-box;
}

.footer-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  background: #22CC9D;
  border-radius: 4px;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.footer-links li:hover,
.footer-services li:hover {
  text-decoration: none;
}
@media (min-width: 1300px){
  .footer-links a,
  .footer-services li{
    font-size: 20px;
  }
}

@media (max-width: 768px) {
  .footer {
    padding: 20px 10px;
  }

  .footerhead {
    font-size: 20px;
  }

  .footersub {
    font-size: 16px;
    line-height: 24px;
    width: 100%; /* Full width on smaller screens */
  }

  .footer-column {
    height: auto; /* Adjust to content height */
    padding-bottom: 20px;
  }

  .footer-logo {
    max-width: 200px; 
    margin-top: 20px;
    margin-left: -9px;
  }

  .social-icons i {
    width: 30px;
    height: 30px;
    font-size: 20px;
  }

  .footer-button {
    font-size: 14px;
    padding: 5px 15px;
  }

  .footer-links a,
  .footer-services li {
    font-size: 16px;
    line-height: 28px;
  }

  .footer-bottom-text {
    font-size: 14px;
  }
  .social-icons{
    margin-top: 0px;
  }
  .follow-us{
    margin-top: 20px;
    line-height: 10px;
  }
}

@media (max-width: 576px) {
  .footerhead
 {
    font-size: 22px;
  }

  .footer-button {
    font-size: 12px;
    width: 100%; /* Full width button */
    height: auto;
    padding: 5px 10px;
  }

  .footer-logo,
  .social-icons i {
    width: auto;
    height: auto;

  }

  .footer-bottom-text {
    font-size: 8px;
  }
}
@media (max-width:381px) {
  
  .footer-links a,
  .footer-services li {
    font-size: 14px;
   
  }
  
}