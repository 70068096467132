.landing-page {
    padding: 40px 44px !important; 
    height: 100vh;
    background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100vh;
  z-index: 2;
  position: relative;
}
.shared-background {
    background: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, rgb(0, 0, 0) 100%, rgba(0, 0, 0, 0.6) 100%), url('../images/landinggif.gif');
    background-size: cover;
  }
.text-content {
    padding-left: 30px; 
    padding-top: 0px;
}

.lanhead, .lanhead2 {
    font-family: 'Albert Sans', sans-serif;
    font-size: 40px;
    font-weight: 600;
    line-height: 72px;
    letter-spacing: -0.03em;
    text-align: left;
   

}
.lanhead{
    color: #FAFAFA;
}
.lanhead2 {
    color: #22CC9D;
}

.text-content p {
    font-family: 'Barlow', sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 40px;
    letter-spacing: -0.03em;
    text-align: left;
    margin-top: 0%;
    color: #FAFAFA;
    text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.6);

}
.sample{
    margin-top: -3%;
}

.custom-button2 {
    width: 246.5px; /* Adjusted width */
    height: 58px; /* Fixed height */
    padding: 14px 26px; /* Padding for button */
    border-radius: 8px;
    border: none;
    background-color: #22CC9D;
    font-size: 20px;
    color: white;
    font-family: 'Albert Sans', sans-serif;
    margin-top: 5%;
}

/* Align the image to the right */
.image-containerl {
    display: flex;
    justify-content: flex-end;
}

.landing-image {
    max-width: 100%; /* Ensure the image is responsive */
    height: auto; /* Maintain aspect ratio */
}

/* Adjustments for responsiveness */
@media (max-width: 768px) {
    .landing-page {
        padding: 20px !important; /* Reduce padding on smaller screens */
    }

    .text-content {
        padding-left: 15px; /* Adjust for smaller screens */
        padding-top: 1%; /* Adjust for smaller screens */
       
    }

    .lanhead, .lanhead2 {
        font-size: 38px; 
        line-height: 34px; 
    }

    .text-content p {
        font-size: 18px;
        line-height: 37px;
        margin-top: 21px;
    }

    .custom-button2 {
        width: 100%; /* Full width for button on smaller screens */
    }

    .image-containerl {
        justify-content: center; /* Center image on smaller screens */
        margin-top: 20px !important; /* Add margin for spacing */
    }
}

@media (min-width: 1300px) {
    .text-content p{
font-size: 24px;
    }

}
