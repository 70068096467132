.enquiry-container {
    overflow-x: hidden;
    max-width: 100vw; 
    padding: 0; 
  }

.enquiryhead, .forme{
    padding: 30px;
}


.map-container {
  width: 80%;
  height: 450px;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin: 20px 0;
  
}

  
  .image-col {
    position: relative;
  }
  
  .image-bg {
    position: relative;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 100%), url('../images/enquiry.jpeg');
    background-size: cover;
    background-position: center;
    height: 100%;
  }
  
  .enquiryhead {
    font-family: 'Albert Sans', sans-serif;
    font-size: 36px;
    font-weight: 600;
    line-height: 54px;
  }
  
  .enquirysubhead {
    font-family: 'Albert Sans', sans-serif;
    font-size: 14px;
    padding-left: 30px;
    margin-top: -4%;
  }
  .enquirybutton{
width: 100%;
background-color: #22CC9D;
border-radius: 6px;
border: none;
font-family: 'Albert Sans', sans-serif;
font-size: 20px;
font-weight: 700;
line-height: 30px;
letter-spacing: -0.03em;
text-align: center;
color: white;
padding: 10px;

  }
  .enquiry-image {
    width: 100%;
    height: 100vh;
    object-fit: cover;
    opacity: 0;
  }
  
  .overlay-content {
    position: absolute;
    bottom: 0px;
    left: 0;
    right: 0;
    text-align: center;
    padding: 40px;
  }
  
  .image-description {
    font-family: 'Albert Sans', sans-serif;
    font-size: 16px;
    font-weight: 600;
    color: #FFFFFF;
    width: 90%;
    margin-top: 4%;
    padding-left: 10px;
    text-align: left;
  }
  
  .social-icons {
    margin-top: 25px;
    text-align: left;
  }
  
  .social-icon {
    margin: 0 10px;
    width: 25.84px;
    height: 26px;
    color: white;
  }
  
  .input-with-icon {
    position: relative;
  }
  
  .input-icon {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    color: #6c757d;
  }
  .overlay3 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* background-color: rgba(0, 0, 0, 0.5);  */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ensure it's above the form */
  }
  .icon-input {
    padding-left: 45px; 
    text-indent: 20px;  
    border: 1px solid #BDBDBD !important;
border-radius: 6px !important;
padding: 15px !important;
font-size: 16px !important;
font-family: 'Albert Sans', sans-serif;
font-weight: 600 !important;
line-height: 24px !important;
letter-spacing: -0.03em;
text-align: left;

}
.icon-input::placeholder {
    color: #828282 !important; /* Set your desired placeholder color */
    opacity: 1; /* Ensures full color intensity */
}

  
  
  
@media (max-width: 576px) {
    .last{
      margin-top: 5%;
    }
  }
  
  